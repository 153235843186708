import React, { useEffect, useContext, Fragment } from 'react';
import { string, shape, bool, number } from 'prop-types';
import { Col } from '@thd-olt-component-react/grid';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Carousel } from '@thd-olt-component-react/carousel';
import { GeneralCardMobile } from './GeneralCardMobile';
import { getCardType, FULL_CARD } from '../Helpers';
import Model from '../model/Model';
import { dataModel } from '../dataModel';
import './category-cards-mobile.scss';
import { useCategoryCard } from '../../hooks/useCategoryCard';
import { ImpressionProvider } from '@thd-olt-component-react/impression';


const CategoryCardsMobile = ({
  keyword,
  navParam,
  storeId,
  data: propData,
  loading: propLoading,
  error: propError,
  ssr,
  componentPosition,
  category
}) => {

  const { store, clientStore } = useContext(ExperienceContext);
  const { data, loading, error } = useCategoryCard({
    clientStore,
    store,
    storeId,
    keyword,
    navParam,
    propLoading,
    propData,
    propError,
    ssr
  });

  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('category-cards.ready'); }, []);
  const categoryCardsContainerImpression = {
    id: '',
    component: 'CategoryCards',
    name: 'CategoryCards',
    type: 'content'
}
  if (!keyword && !navParam && typeof propLoading === 'undefined') {
    return null;
  }

  const renderFull = (categoryCards) => {
    const { majorDetails, majorHeader } = categoryCards;

    if (!majorHeader || !majorDetails) return null;

    return (
      <ImpressionProvider
      data={categoryCardsContainerImpression}
  >
      <div className="category-cards" data-component="CategoryCardsMobile" id={`${data.id}-${data.component}-${componentPosition}`}  data-component-position={componentPosition}>
        <Col className="category-cards__zone-wrapper category-cards__full-card">
          <GeneralCardMobile type={FULL_CARD} header={majorHeader} details={majorDetails} componentPosition={componentPosition} category={category} />
        </Col>
      </div>
      </ImpressionProvider>
    );
  };

  const getCarouselCards = (carouselCards = []) => {
    return carouselCards?.filter((carouselCard) => carouselCard)?.map((carouselCard, i) => {
      return <Fragment key={`card-${i}`}>{carouselCard}</Fragment>;
    });
  };

  const renderZone = (categoryCards) => {
    const { majorHeader, majorDetails, minorHeader, minorDetails } = categoryCards;

    if (!majorHeader && !majorDetails && !minorHeader && !minorDetails) return null;

    const carouselContentCards = [
      (majorHeader
        && majorDetails
        && <GeneralCardMobile type={getCardType(majorHeader.title)} header={majorHeader} details={majorDetails} componentPosition={componentPosition} category={category} />
      ),
      (minorHeader
        && minorDetails
        && <GeneralCardMobile type={getCardType(minorHeader.title)} header={minorHeader} details={minorDetails} componentPosition={componentPosition} category={category} />
      )
    ];

    return (
      <Col className="category-cards" data-component="CategoryCardsMobile">
        <div className="category-cards__zone-wrapper category-cards__zone-card" id={`${data.id}-${data.component}-${componentPosition}`}  data-component-position={componentPosition}>
          <Carousel
            multiItem={false}
            showDots
            dotBelow
            showArrows={false}
          >
            {getCarouselCards(carouselContentCards)}
          </Carousel>
        </div>
      </Col>
    );
  };

  if (loading && !data) {
    return <div data-component="CategoryCardsMobilePlaceholder">Loading...</div>;
  }

  if (!data || !data?.searchModel?.orangeGraph || (!data && error)) {
    return null;
  }

  const categoryCards = new Model(data.searchModel.orangeGraph);

  return categoryCards.isFullCard('mobile')
    ? renderFull(categoryCards)
    : renderZone(categoryCards);
};

CategoryCardsMobile.displayName = 'CategoryCardsMobile';

CategoryCardsMobile.dataModel = dataModel;

CategoryCardsMobile.propTypes = {
  keyword: string,
  navParam: string,
  data: shape({}),
  loading: bool,
  error: shape({}),
  ssr: bool,
  storeId: string,
  componentPosition: number,
  category: string
};

CategoryCardsMobile.defaultProps = {
  keyword: null,
  navParam: null,
  data: undefined,
  loading: undefined,
  error: undefined,
  ssr: false,
  storeId: null,
  componentPosition: 1,
  category: ''
};

export { CategoryCardsMobile };
