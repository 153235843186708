import React from 'react';
import { string, number } from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import { ACTION, RENTAL, RENTAL_INTENT_POD_CHECK_AVAILABILITY } from '../../util/constants';
import styles from '../../styles/rental-intent-pod.module.scss';
import { publish } from '../../analytics';

const RentalIntentPodMobile = ({
  index,
  imageUrl,
  link,
  title,
  description
}) => {
  const onClick = ({
    podPosition,
    podInteractionNumber
  }) => {
    publish(RENTAL_INTENT_POD_CHECK_AVAILABILITY, {
      podType: RENTAL,
      podAction: ACTION,
      podInteractionNumber,
      podPosition
    });
  };
  return (
    <a
      className={classNames(styles['rental-intent-pod-mobile'])}
      href={link}
      onClick={() => {
        onClick({
          podPosition: `r${index + 1}`,
          podInteractionNumber: '1'
        });
      }}
    >
      <div className={classNames(styles['rental-intent-pod-mobile__imageWrapper'])}>
        <Image
          height="150px"
          width="150px"
          className={classNames(styles['rental-intent-pod-mobile__image'])}
          alt={title}
          src={imageUrl}
        />
      </div>
      <div className={classNames(styles['rental-intent-pod-mobile__contentWrapper'])}>
        <div className={classNames(styles['rental-intent-pod-mobile__title'])}>{title}</div>
        <div className={classNames(styles['rental-intent-pod-mobile__description'])}>{description}</div>
      </div>
    </a>
  );
};

RentalIntentPodMobile.displayName = 'RentalIntentPodMobile';

RentalIntentPodMobile.propTypes = {
  index: number,
  imageUrl: string,
  link: string,
  title: string,
  description: string
};

RentalIntentPodMobile.defaultProps = {
  index: 0,
  imageUrl: '',
  link: '',
  title: '',
  description: ''
};

export { RentalIntentPodMobile };
