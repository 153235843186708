import React, { useContext } from 'react';
import { AppContext } from '@thd-nucleus/app-render';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useLocation } from '@thd-olt-component-react/router';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { string, number } from 'prop-types';
import { useStore, useConfigService } from '@thd-nucleus/experience-context';
import {
  getContentfulPreviewCustomerType,
  getContentfulPreviewPathName
} from './browse-and-search-utils';

import { BrowseAndSearchMobile } from './BrowseAndSearchMobile';

const browseDefaultVariables = {
  skipInstallServices: false,
  skipSpecificationGroup: false,
  isBrandPricingPolicyCompliant: false
};

export const BrowseAndSearchMobileContainer = (props) => {
  const isBuyitagainEnabled = useConfigService('fs-prop:isBuyitagainEnabled');
  const { isCustomerIdentified = false } = useThdCustomer() || {};

  if (browseDefaultVariables) {
    browseDefaultVariables.skipBuyitagain = !isBuyitagainEnabled;
    browseDefaultVariables.isBrandPricingPolicyCompliant = isCustomerIdentified;
  }
  const { pageType, searchTimeout } = props;
  const { pathname } = useLocation();
  const { instance } = useContext(AppContext);
  let customerType = instance?.customer?.type;
  const searchSkipFn = ({ skip, queryName, attributes }) => {
    if (queryName === 'searchStreamProduct') {
      if (!attributes?.searchModel?.products?._children?.fulfillment) return true;
    }
    return skip;
  };
  const isDiscoveryZonesEnabled = useConfigService('fs-prop:isDiscoveryZonesEnabled');
  const isDiscoveryZonesSearchEnabled = useConfigService('fs-prop:isDiscoveryZonesSearchEnabled');
  browseDefaultVariables.skipDiscoveryZones = !(isDiscoveryZonesEnabled && pageType === 'browse')
  && !(isDiscoveryZonesSearchEnabled && pageType === 'search');

  const searchOptsFn = ({ options, queryName }) => {
    if (queryName === 'searchStreamProduct') {
      const variables = options.variables;
      // eslint-disable-next-line no-param-reassign
      options.variables = undefined;
    }

    if (queryName === 'searchModel') {
      let to = 3000;
      if (searchTimeout) {
        if (typeof searchTimeout === 'string') {
          to = parseInt(searchTimeout, 10);
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(to)) {
            to = 3000;
          }
        } else if (typeof searchTimeout === 'number') {
          to = searchTimeout;
        }
      }

      // eslint-disable-next-line no-param-reassign
      options.context = {
        ...(options.context || {}),
        timeout: to
      };
    }

    return options;
  };

  // --this is specific for being able to render pages for Contentful's previews
  const previewLink = instance?.headers?.['x-content-preview'];
  let finalPathname = pathname;
  if (previewLink) {
    finalPathname = getContentfulPreviewPathName(pathname);
    customerType = getContentfulPreviewCustomerType(pathname);
  }

  if (pageType === 'browse') {
    return (
      <QueryProvider
        skip={searchSkipFn}
        queryOptions={searchOptsFn}
        defaultVariables={browseDefaultVariables}
        cacheKey="browse-search-mobile"
      >
        <BrowseAndSearchMobile
          pathname={finalPathname}
          customerType={customerType}
          instance={instance}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
        />
      </QueryProvider>
    );
  }
  return (
    <QueryProvider
      defaultVariables={browseDefaultVariables}
      forceClientQueriesOnServer={['searchModel', 'product']}
      cacheKey="browse-search-mobile"
    >
      <BrowseAndSearchMobile
        pathname={finalPathname}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      />
    </QueryProvider>
  );

};
BrowseAndSearchMobileContainer.propTypes = {
  pageType: string.isRequired,
  searchTimeout: string,
  productPodHoverDelay: number
};
BrowseAndSearchMobileContainer.defaultProps = {
  searchTimeout: null,
  productPodHoverDelay: 500
};
