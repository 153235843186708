import React, { memo } from 'react';
import { shape, string } from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import { FULL_CARD, RENTAL_CARD } from '../Helpers';
import { useImpression } from '@thd-olt-component-react/impression';
import './GeneralCardMobile.scss';

const GeneralCardMobile = ({ header, details, type, componentPosition, category }) => {

  const { title, description: headerDescription } = header;
  const { description, imageUrl, link } = details;
  const specialSymbol = 'â€™';
  if (!type) {
    return null;
  }
  const isFullCard = type === FULL_CARD;
  const isRentalCard = type === RENTAL_CARD;
  const detailsDescription = isFullCard ? description?.replace(specialSymbol, '\'') : description;
  const { ref, clickID } = useImpression({
    data: {
      id: "00DEFVALCC00",
      name: 'CategoryCards',
      component: 'CategoryCards',
      position: componentPosition,
      type: 'content',
      category: category
    }
  });
  const linkClasses = classNames(
    'carousel-card',
    { 'full-carousel-card': isFullCard }
  );

  const titleClass = classNames(
    'carousel-card__header',
    'u__bold',
    { 'carousel-card__rental-header': isRentalCard }
  );

  const descriptionClass = classNames(
    'carousel-card__text',
    'carousel-card__text--truncate',
    { 'carousel-card__rental-text': isRentalCard }
  );

  return (
    <a className={linkClasses} href={link} ref={ref} clickid={clickID}>
      <div className="carousel-card__header-wrapper">
        <div className={titleClass}>{title}</div>
        <div className="carousel-card__header-text">{headerDescription}</div>
        <div className="carousel-card__title-border" />
        <div className="carousel-card__description">
          <div className={descriptionClass}>
            {detailsDescription}
          </div>
        </div>
      </div>
      <div className="carousel-card__image-wrapper">
        <div className="carousel-card__image">
          <Image src={imageUrl} alt="" width="1" height="1" />
        </div>
      </div>
    </a>
  );
};

GeneralCardMobile.displayName = 'CategoryCardsMobile-GeneralCard';

GeneralCardMobile.propTypes = {
  header: shape({
    title: string,
    description: string
  }).isRequired,
  details: shape({
    imageUrl: string,
    description: string,
    link: string
  }).isRequired,
  type: string
};

GeneralCardMobile.defaultProps = {
  type: null
};

const MemoGeneralCardMobile = memo(GeneralCardMobile);

export { MemoGeneralCardMobile as GeneralCardMobile };
